<template>
  <div>
    <v-row v-if="!loading">
      <v-col cols="12" sm="6" class="pl-3 pr-2">
        <material-card color="secondary" title="Статистика">
          <template v-slot:heading>
            <div class="px-3 py-1 d-flex">Статистика <v-spacer /> <v-icon small @click="editPlanning()">fas fa-wrench</v-icon></div>
          </template>
          <v-simple-table dense class="block_master_stat">
            <thead>
              <tr>
                <td :style="`font-weight: 500; height:  22px`"></td>
                <td class="text-center font-weight-bold px-0 mx-1" :style="`height: 22px; background-color:rgba(255, 159, 64, 0.2)`">План</td>
                <td class="text-center font-weight-bold px-0 mx-1" :style="`height: 22px; background-color:rgba(75, 192, 192, 0.2) `">Факт</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(el, i) in model"
                :key="i"
                v-if="el.hidden != true && el.type && (el.name == 'loan_body' || getValue(el.name) || balanceCalc[el.calc]) && fieldsNote[el.name]?.hidden !== true"
                :field-name="el.name"
              >
                <td class="text-left" :style="{ 'font-weight': 500, height: '38px', 'white-space': 'normal', color: el.name.split('_')[0] == 'operation' ? 'red' : '' }">
                  <v-tooltip v-if="fieldsNote[el.name] && fieldsNote[el.name].description" right max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        {{ fieldsNote[el.name].title || el.title }}
                        <v-icon small>mdi-information</v-icon>
                      </span>
                    </template>
                    <span>{{ fieldsNote[el.name].description }}</span>
                  </v-tooltip>
                  <span v-else>
                    {{ (fieldsNote[el.name] && fieldsNote[el.name].title) || el.title }}
                  </span>
                </td>
                <td class="text-left px-0 pl-2" :style="`height: 38px; background-color:rgba(255, 159, 64, 0.2)`">
                  <component v-if="el.type" :is="'a-view-' + el.type" :model="el" :values="data" :value="el.value || getValue(el.name)" @input="data[el.name] = $event" />
                </td>
                <td
                  class="text-left px-0 pl-2"
                  :class="{ active: el.name == curValueDetail }"
                  :style="{ height: '38px', 'background-color': 'rgba(75, 192, 192, 0.2)', color: el.showDetail ? 'blue' : '' }"
                  @click="showValueDetail(el)"
                >
                  <component v-if="el.type" :is="'a-view-' + el.type" :model="el" :values="data" :value="balanceCalc[el.calc]" @input="data[el.name] = $event" />
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </material-card>
      </v-col>
      <v-col cols="12" sm="6" class="pl-2 pr-3">
        <material-card color="secondary" ref="detail">
          <template v-slot:heading> <div class="px-3 py-1">Детали</div></template>
          <v-card-text :style="`height: ${getHeightBlock('.block_master_stat')}px ; overflow: auto`" class="pa-0">
            <a-table-f-data2
              v-if="curValueDetail"
              ref="tableDetail"
              :dataTable="detailData"
              :model="detailModel"
              :useQuery="false"
              :footer="detailFooter"
              :defaults="{
                sort: {},
              }"
              @rowClick="$emit('tableClick', $event)"
            />
            <div v-else class="showEmptyText">
              <div class="align-self-center">Выберете значение для детализации</div>
            </div>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getAccess, autoHeightBlock, popupMenu, libObjectPlanning } from "@/components/mixings";
export default {
  mixins: [getAccess, autoHeightBlock, popupMenu, libObjectPlanning],
  components: {},
  props: {
    id: Number,
    expensesCalc: Object,
  },
  data() {
    return {
      data: {},
      idEdit: 0,
      loading: true,
      fieldsNote: this.$store.state.config.data.config.dictionary?.objectPlanningFields || {},
      showEditDialog: false,
      title: "",
      model1: [
        { name: "price_sell", hidden: true, default: 0 },
        { name: "plan_sell_price", title: "Стоимость продажи", type: "number", edit: true },
        { name: "plan_date_finish", title: "Плановые дата готовности", type: "date" },
        { name: "percent_finish", title: "Процент готовности", value: "", calc: "progress", type: "string" },
        { name: "credit_sum", title: "Кредит по ипотеке (тело)", type: "number", calc: "type_21", showDetail: "value,operation_name,base_doc_name" },
        { name: "loan_body", title: "Ипотечные платежи (тело) ", type: "number", calc: "type_744", showDetail: "operation_id,value,operation_name,base_doc_name" },
        { name: "loan_percent", title: "% по ипотеке", type: "number", calc: "type_23", showDetail: "value,operation_name,base_doc_name" },
        { name: "first_payment", title: "Первоначальный взнос (компания)", type: "number", calc: "type_22", showDetail: "value,operation_name,base_doc_name" },
        { name: "first_payment_inv", title: "Первоначальный взнос (инвестор)", type: "number", calc: "type_24", showDetail: "value,operation_name,base_doc_name" },
        {
          name: "investment_expenses_total",
          title: "Общие расходы инвестора",
          type: "number",
          calc: "type_70",
          showDetail: "base_doc_name,value,operation_id,operation_name,credit_subconto_value_1_name",
        },
        {
          name: "data.investment_amount",
          title: "Сумма инвестирования",
          type: "number",
          json: true,
          calc: "type_201",
          showDetail: "base_doc_name,value,operation_id,operation_name,credit_subconto_value_1_name",
        },
        {
          name: "investment_amount_total",
          title: "Общие вложения инвестора",
          type: "number",
          calc: "type_2",
          showDetail: "base_doc_name,value,operation_id,operation_name,credit_subconto_value_1_name",
        },
        {
          name: "plan_expenses",
          title: "Стоимость реализации",
          type: "number",
          calc: "type_1",
          showDetail: "base_doc_name,value,operation_id,debit_subconto_value_3_name",
        },
        {
          name: "dept_expenses",
          title: "Незавершенные платежи",
          type: "number",
          calc: "type_-1",
          showDetail: "base_doc_name,value,operation_id,debit_subconto_value_3_name",
        },
        {
          name: "plan_org_expenses",
          title1: "Организационные затраты",
          title: "Расходы на оформление",
          type: "number",
          size: 6,
          calc: "type_3",
          showDetail: "value,operation_id,base_doc_name,debit_subconto_value_3_name",
        },
        {
          name: "plan_adm_expenses",
          title: "Административные расходы",
          type: "number",
          size: 6,
          calc: "type_4",
          showDetail: "value,operation_id,base_doc_name,debit_subconto_value_3_name",
        },
        {
          name: "plan_investment_calc",
          title: "Личные вложения",
          type: "number",
          readonly: true,
          calculated: true,
          formula: `((d) => {return d.plan_money6 > d.data.investment_amount ? d.plan_money6 - d.data.investment_amount :0;})`,
          calc: "type_90",
        },
        { name: "profit", title: "Прибыль сейчас", type: "number", calc: "type_99" },
        { name: "profit_perc", title: "% Прибыли сейчас", type: "number", calc: "profit" },
        { name: "money_inv_balance", title: "Остаток средств инвестора", type: "number", value: null, calc: "type_20" },
      ],
      modelAcc: this.$store.getters["config/get"].models.accRecords.list,
      url: "/mechti/objects",
      tab: 0,
      balanceData: [],
      balanceFooter: {},
      balanceCalc: {},
      clientHeight: 0,
      curValueDetail: null,
      detailData: [],
      detailModel: [],
      detailFooter: {},
    };
  },
  created() {
    this.loading = true;
    this.permit = this.getAccess("menu.objects");
    this.fitchData();
  },
  watch: {
    "data.price_sell"() {
      this.setProfit();
    },
    "data.plan_sell_price"() {
      this.setProfit();
    },
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
    curValueDetail() {
      this.getValueDetail();
    },
  },
  computed: {
    model() {
      let model = this.model1;
      return model;
    },
  },
  methods: {
    editPlanning() {
      this.$emit("onEdit");
      return;
      this.showEditDialog = true;
    },
    getValueDetail() {
      this.detailData = [];
      this.detailModel = [];
      if (!this.curValueDetail) {
        return;
      }
      let el = this.model.find((f) => f.name == this.curValueDetail);
      if (!el) return;
      let type = el?.calc;
      if (!type) return;
      if (type.split("_")[0] == "operation") {
        type = parseInt(type.split("_")[1]);
        this.detailModel = this.modelAcc.filter((m) => el.showDetail.split(",").includes(m.name));
        this.detailData = this.balanceData.filter((d) => {
          return d.operation_id == type;
        });
      } else {
        type = parseInt(type.split("_")[1]);
        this.detailModel = [...this.modelAcc.filter((m) => el.showDetail.split(",").includes(m.name))];
        this.detailModel.forEach((m) => {
          m["searchable"] = false;
        });

        this.detailData = this.balanceData.filter((d) => {
          return d.type ? d.type.includes(type) : false;
        });
      }
      this.detailFooter["Всего записей"] = this.detailData.length;
      this.detailFooter["Сумма"] = this.detailData.reduce((total, curr) => total + parseFloat(curr.value), 0).toLocaleString();
    },
    showValueDetail(el) {
      this.curValueDetail = el.showDetail ? el.name : null;
    },
    getValue(n) {
      let res = this.data;
      n.split(".").forEach((p) => {
        res = res[p];
      });
      return res;
    },
    setProfit() {
      let profit =
        (this.balanceCalc?.type_23 || 0) +
        Math.max(this.data.plan_org_expenses || 0, this.balanceCalc?.type_3 || 0) +
        Math.max(this.data.plan_adm_expenses || 0, this.balanceCalc?.type_4 || 0) +
        Math.max(this.data.plan_expenses || 0, this.balanceCalc?.type_1 || 0);
      profit =
        (this.balanceCalc?.type_23 || 0) +
        (this.balanceCalc?.type_3 || this.data.plan_org_expenses || 0) +
        (this.balanceCalc?.type_4 || this.data.plan_adm_expenses || 0) +
        (this.balanceCalc?.type_1 || this.data.plan_expenses || 0);

      let res =
        (this.data.price_sell || this.data.plan_sell_price || 0) -
        Math.max(this.data.credit_sum || 0, (this.balanceCalc?.type_21 || 0) + (this.balanceCalc?.type_22 || 0) + (this.balanceCalc?.type_24 || 0)) -
        profit;
      if (!true)
        res =
          (this.data.price_sell || this.data.plan_sell_price || 0) -
          ((this.balanceCalc?.type_21 || 0) + (this.balanceCalc?.type_22 || 0) + (this.balanceCalc?.type_24 || 0) || this.data.credit_sum || 0);
      /* console.log("rrrrr", [
        this.data.price_sell || this.data.plan_sell_price || 0,
        Math.max(this.data.credit_sum || 0, (this.balanceCalc?.type_21 || 0) + (this.balanceCalc?.type_22 || 0) + (this.balanceCalc?.type_24 || 0)),
        profit,
      ]); */
      this.balanceCalc["type_99"] = res;
      this.balanceCalc["profit"] = (res / profit) * 100;
    },
    async fitchData() {
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
        let progress = await this.getObjectProgress(this.data.id);
        this.data.progress = progress;
        this.fillData(this.model);
      }
      this.afterFetchData();
      this.getBalanceTable();
      //this.calcExpenses();
      this.balanceCalc["type_99"] = this.type_99;

      this.loading = false;
    },
    fillData(model) {
      for (let el of model) {
        if (el?.calculated == true) {
          if (el?.formula) {
            let f = el.formula;
            Object.defineProperty(this.data, el.name, {
              get: function () {
                let res = 0;
                let func;
                if (typeof f == "function") func = f;
                if (typeof f == "string") func = eval(f);
                res = func(this);
                return res;
              },
            });
          }
        }
      }
    },

    formatNumber(row, col, value, index) {
      return isNaN(value) ? value : value ? value.toLocaleString("ru-Ru") : "";
    },
    async getBalanceTable() {
      let documents = await this.getDocumentPayments({ id: this.id });
      let balance = await this.getObjectBalance({ id: this.id });
      this.balanceData = balance;
      let calc = this.calcBalance(this.data, balance, documents);
      this.balanceCalc = calc.calc;
      const progress = (d) => {
        if (!d.progress) return "";
        let res = "";
        let total = 0;
        let done = 0;
        //let p = { Покупки: 0, Дизайнер: 0, Стройка: 0, Мебель: 0 };
        let p = { Покупки: 0, Дизайнер: 0, Стройка: 0 };
        if (!true)
          d.progress.forEach((p) => {
            let per = p.per || 0;
            res = res + (res ? " " : "") + p.name.substr(0, 1) + per.toFixed(0);
            total += 100;
            done += p.per || 0;
          });
        else {
          d.progress.forEach((r) => (p[r.name] = r.per));
          for (const [key, value] of Object.entries(p)) {
            res = res + (res ? " " : "") + key.substr(0, 1) + value.toFixed(0);
            total += 100;
            done += value;
          }
        }
        res = ((100 * done) / (total || 1)).toFixed(2) + `% (${res})`;
        return res;
      };

      this.balanceCalc["progress"] = progress(this.data);

      for (const key in calc.calc2) {
        if (Object.hasOwnProperty.call(calc.calc2, key)) {
          const el = calc.calc2[key];
          let m = {
            name: "operation_" + key,
            title: this.$root.dirs.operationTable.find((o) => o.value == key)?.text || key,
            type: "number",
            calc: "operation_" + key,
            showDetail: "value,operation_id,base_doc_name",
          };
          m = [...this.model, m];
          this.model1 = m;
          calc.calc["operation_" + key] = calc.calc2[key];
        }
      }
      this.balanceCalc = calc.calc;

      this.balanceCalc["investment_amount"] = this.balanceData
        .filter((d) => {
          return (d.type ? d.type.includes(2) : false) && ![62].includes(d.operation_id);
        })
        .reduce((a, b) => {
          return a + parseFloat(b.value || 0);
        }, 0);
      this.balanceData.forEach((d) => {
        if ((d.type ? d.type.includes(2) : false) && ![62].includes(d.operation_id)) {
          d.type.push(201);
          this.balanceCalc["type_201"] = (this.balanceCalc?.["type_201"] || 0) + d.value;
        }
      });
      this.setProfit();
      this.balanceFooter["Всего записей"] = balance.length;
      this.balanceFooter["Сумма"] = balance
        .reduce((total, curr) => total + parseFloat(curr.value), 0)
        .toFixed(2)
        .toLocaleString();
      this.$emit("onBalance", { document: this.documentData, data: this.balanceData, footer: this.balanceFooter, calc: this.balanceCalc });
    },

    updateData() {
      this.fitchData();
    },

    afterFetchData() {},
  },
};
</script>

<style lang="sass">
.no-underline .v-input__slot::before
  border: none !important

.el-table .cell
  word-break: break-word
.showEmptyText
  position: absolute
  width: 100%
  height: 100%

td.active
  background-color: rgba(75, 192, 192, 0.4) !important
.legend-chk
  margin: 0 !important
  padding: 0 !important

.v-slide-group1__prev--disabled
  display: none !important
.v-slide-group1__next--disabled
  display: none !important
</style>
